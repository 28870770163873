import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gridArea: "banner",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "30px",
    position: "relative",

    [theme.breakpoints.up("lg")]: {
      background: "url('/img/square-right.svg') 93% 20% / 70px no-repeat",
      justifyContent: "space-between",

      "&::before": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        content: "''",
        display: "block",
        height: "11px",
        left: "19%",
        position: "absolute",
        bottom: "-7%",
        width: "11px",
      },
    },
  },
  image: {
    display: "none",
    alignSelf: "center",
    maxWidth: "340px",
    width: "70%",

    [theme.breakpoints.up("md")]: {
      display: "block",
      alignSelf: "baseline",
      maxWidth: "600px",
      width: "72%",
      transform: "translateY(14px)",
    },
  },
}));

const LoginBanner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{ minHeight: "160px" }}>
        <Typography variant="h1">Seja bem-vindo</Typography>
        <Typography variant="subtitle1" gutterBottom>
          Área Loop para revendedores.
        </Typography>
      </div>
      <img className={classes.image} src="/img/white-car.png" alt="" />
    </div>
  );
};

export default LoginBanner;
